<template lang="pug">
  .dashboard
    .dashboard-sidebars(sticky="true").d-none.d-sm-block.d-md-block
      Sidebar-user
    .dashboard-content
      b-container
        b-row
          b-col.pt-4
            list
      FooterNavbarMobile
</template>
<script>
import list from "@/components/brand/list"
import SidebarUser from "@/components/dashboard/SidebarUser"
import FooterNavbarMobile from "@/components/FooterNavbarMobile"
export default {
  name: "BrandAdmin",
  components: {
    list,
    SidebarUser,
    FooterNavbarMobile
  },
}
</script>